import type { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { useState } from 'react'

import { getPlain } from '../../../../store/utils'
import EmailForm from './EmailForm'
import Lightbox from '../../../templateComponents/Lightbox'
import MasterDataForm from './MasterDataForm'
import PasswordForm from './PasswordForm'
import Portal from '../../../templateComponents/Portal'
import translate from '../../../../utils/translate'

export default translate('views.storefrontView.customerAccountSection.profileTab')(CustomerProfile)

function CustomerProfile({ t }: TranslateProps): ReactElement {
  const billingAddress = getPlain(
    useSelector<State, Core.Customer['billingAddress']>((state) => state.getIn(['customer', 'billingAddress'], {})),
  )

  const name = [billingAddress.firstName, billingAddress.lastName].filter(Boolean).join(' ')

  const [showForm, setShowForm] = useState<null | 'master-data' | 'email' | 'password'>(null)
  const hideForm = () => setShowForm(null)

  return (
    <section className="subpage">
      <div className="subpage-item">
        <div className="subpage-item-headline">
          <h2 className="subpage-item-headline-title">{t('masterDataSection.title')}</h2>
          <button className="button-link" onClick={() => setShowForm('master-data')}>
            <span className="button-link-text">{t('masterDataSection.editButton.label')}</span>
          </button>
        </div>
        <p>{name}</p>
        {billingAddress.phone && <p>{billingAddress.phone}</p>}
      </div>
      <div className="subpage-item">
        <div className="subpage-item-headline">
          <h2 className="subpage-item-headline-title">{t('emailSection.title')}</h2>
          <button className="button-link" onClick={() => setShowForm('email')}>
            <span className="button-link-text">{t('emailSection.editButton.label')}</span>
          </button>
        </div>
        <p>{billingAddress.email}</p>
      </div>
      <div className="subpage-item">
        <div className="subpage-item-headline">
          <h2 className="subpage-item-headline-title">{t('passwordSection.title')}</h2>
          <button className="button-link" onClick={() => setShowForm('password')}>
            <span className="button-link-text">{t('passwordSection.editButton.label')}</span>
          </button>
        </div>
        <p>*****************</p>
      </div>

      <Portal>
        <Lightbox show={Boolean(showForm)} onClose={hideForm} lightboxClassName="lightbox-modal">
          {({ close }) => (
            <>
              {showForm === 'master-data' && <MasterDataForm onClose={close} billingAddress={billingAddress} />}
              {showForm === 'email' && <EmailForm onClose={close} />}
              {showForm === 'password' && <PasswordForm onClose={close} />}
            </>
          )}
        </Lightbox>
      </Portal>
    </section>
  )
}
