import { useCallback } from 'react'
import { useSelector } from 'react-redux'

export default function useDateTimeFormatter(): (dateTime?: string, options?: Intl.DateTimeFormatOptions) => string {
  const locale = useSelector<State, string | undefined>((state) => state.getIn(['shop', 'locale']))

  const normalizedLocale = locale?.replace(/_/, '-') // ePagesNow shop locales are underscore separated

  return useCallback(
    (dateTime = '', options: Intl.DateTimeFormatOptions = { year: 'numeric', day: 'numeric', month: 'long' }) => {
      // Work around EPNG-5650
      const parsedDateTime = Date.parse(dateTime.endsWith('Z') ? dateTime : dateTime + 'Z')

      const isValidDate = !Number.isNaN(parsedDateTime)
      if (!isValidDate) return ''

      const formatter = new Intl.DateTimeFormat(normalizedLocale, options)

      return formatter.format(parsedDateTime)
    },
    [normalizedLocale],
  )
}
