import type { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

import { areAddressesTheSame } from '../../../templateComponents/Checkout/utils'
import {
  assignCustomerToCart,
  loadAllCountries,
  updateCustomerBillingAddress,
  updateCustomerShippingAddress,
} from '../../../../store/actions'
import { getPlain } from '../../../../store/utils'
import { toggleLabelInvalidClass } from './AccountMenu'
import CustomerAddressFields from './CustomerAddressFields'
import useAutoFocus from '../../../../utils/hooks/useAutoFocus'

type Props = {
  onClose: () => void
  billingAddress: Core.Customer['billingAddress']
  shippingAddress: Core.Customer['shippingAddress']
  scopeName: 'billingAddress' | 'shippingAddress'
} & TranslateProps

export default function AddressDataForm({
  t,
  onClose,
  billingAddress,
  shippingAddress,
  scopeName,
}: Readonly<Props>): ReactElement {
  const dispatch: ApiActionDispatch = useDispatch<GlobalDispatch>()

  const [submitting, setSubmitting] = useState(false)

  const tFormField = (fieldName: string) => t(`${fieldName}InputField.label`)

  const cart = getPlain(useSelector<State, Core.Cart | null>((state) => state.get('cart', null)))
  const allCountries = useSelector<State, ImmutableList>((state) => state.getIn(['checkout', 'all-countries']))

  const addressBeingEdited = scopeName === 'billingAddress' ? billingAddress : shippingAddress

  useEffect(() => {
    if (!allCountries) dispatch(loadAllCountries())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const useTheSameBillingAddress = useRef(areAddressesTheSame(billingAddress, shippingAddress))
  const form = useRef<HTMLFormElement>(null)
  useAutoFocus(form)

  async function handleSubmit(event) {
    event.preventDefault()
    setSubmitting(true)

    try {
      const newAddressWithoutEmptyStrings = {} as Core.Address
      new FormData(event.target).forEach((value, key) => {
        if (value !== '') newAddressWithoutEmptyStrings[key] = value
      })

      // Email and phone values have edit fields handled in different place
      newAddressWithoutEmptyStrings.email = addressBeingEdited.email as string
      newAddressWithoutEmptyStrings.phone = addressBeingEdited.phone

      if (scopeName === 'billingAddress') {
        await dispatch(updateCustomerBillingAddress(newAddressWithoutEmptyStrings))
        if (useTheSameBillingAddress.current) {
          await dispatch(updateCustomerShippingAddress(newAddressWithoutEmptyStrings))
        }
      } else {
        await dispatch(updateCustomerShippingAddress(newAddressWithoutEmptyStrings))
      }
      // repeat assignment in order to update address data in cart
      if (cart?.productLineItems?.length) await dispatch(assignCustomerToCart())
    } catch {
      // global API error handler takes care
    } finally {
      setSubmitting(false)
    }

    onClose()
  }

  return (
    <form
      ref={form}
      onSubmit={handleSubmit}
      onInvalid={toggleLabelInvalidClass(true)}
      onInput={toggleLabelInvalidClass(false)}
      className="lightbox-modal-form"
    >
      <div className="inner-content">
        <h2 className="title">{t('title')}</h2>
        <CustomerAddressFields address={addressBeingEdited} countries={allCountries} {...{ t, tFormField }} />
        {scopeName === 'billingAddress' && (
          <label className="checkbox-wrapper">
            <input
              name="useTheSameBillingAddress"
              type="checkbox"
              defaultChecked={useTheSameBillingAddress.current}
              onChange={({ target }) => (useTheSameBillingAddress.current = target.checked)}
            />
            <div>{t('shippingAddressIsTheSameAsBillingAddress')}</div>
          </label>
        )}
      </div>
      <div className="button-wrapper">
        <button className="button button-primary button-pending-state pending" type="submit" disabled={submitting}>
          {t('saveButton.label')}
        </button>
        <button type="button" onClick={onClose} className="button button-outline">
          {t('cancelButton.label')}
        </button>
      </div>
    </form>
  )
}
