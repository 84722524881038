import type { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { useRef, useState } from 'react'

import { requestEmailChange } from '../../../../store/actions'
import { toggleLabelInvalidClass } from './AccountMenu'
import PasswordRevealInput from '../PasswordRevealInput'
import translate from '../../../../utils/translate'
import useAutoFocus from '../../../../utils/hooks/useAutoFocus'

type Props = { onClose: () => void } & TranslateProps

export default translate('views.storefrontView.customerAccountSection.profileTab.emailSection.editModal')(EmailForm)

function EmailForm({ t, onClose }: Readonly<Props>): ReactElement {
  const dispatch: ApiActionDispatch = useDispatch<GlobalDispatch>()

  const [error, setError] = useState<string | null>(null)
  const [submitting, setSubmitting] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault()
    setSubmitting(true)

    try {
      setError(null)
      const currentPassword = event.target.elements.currentPassword.value
      const newEmail = event.target.elements.newEmail.value

      await dispatch(requestEmailChange({ currentPassword, newEmail }, { showErrorNotification: false }))
      onClose()
    } catch (error) {
      // scroll up to the error message
      form.current?.scrollIntoView(true)

      if (/current password.*not match/.test(error.message)) {
        setError(t('errors.currentPasswordDoesNotMatch'))
        toggleLabelInvalidClass(true)(form.current?.currentPassword)
      } else if (/The new email address .* is already used/.test(error.message)) {
        setError(t('errors.emailAlreadyRegistered'))
        toggleLabelInvalidClass(true)(form.current?.newEmail)
      } else {
        setError(t('errors.genericServerError'))
      }
    } finally {
      setSubmitting(false)
    }
  }

  const form = useRef<HTMLFormElement>(null)
  useAutoFocus(form)

  return (
    <form
      ref={form}
      onSubmit={handleSubmit}
      onInvalid={toggleLabelInvalidClass(true)}
      onInput={toggleLabelInvalidClass(false)}
      autoComplete="on"
      className="lightbox-modal-form"
    >
      <div className="inner-content">
        <h2 className="title">{t('title')}</h2>
        {error ? <p className="error-message">{error}</p> : <p>{t('explanation')}</p>}
        <label>
          {t('newEmailField.label')}*
          <input type="email" name="newEmail" className="ep-form-text-field" required />
        </label>
        <label>
          {t('currentPasswordField.label')}*
          <PasswordRevealInput
            autoFocus
            name="currentPassword"
            className="ep-form-text-field"
            autoComplete="password"
            required
          />
        </label>
      </div>
      <div className="button-wrapper">
        <button className="button button-primary button-pending-state pending" type="submit" disabled={submitting}>
          {t('saveButton.label')}
        </button>
        <button className="button button-outline" type="button" onClick={onClose}>
          {t('cancelButton.label')}
        </button>
      </div>
    </form>
  )
}
