import type { DetailedHTMLProps, InputHTMLAttributes, ReactElement } from 'react'
import { useState } from 'react'

type Props = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { name: string }

export default function PasswordRevealInput(props: Readonly<Props>): ReactElement {
  const [showPlainPassword, setShowPlainPassword] = useState(false)

  return (
    <div className="password-input-line">
      <input {...props} type={showPlainPassword ? 'text' : 'password'} />
      <button
        className={showPlainPassword ? 'hide-password-icon' : 'show-password-icon'}
        type="button"
        onClick={() => setShowPlainPassword((v) => !v)}
      />
    </div>
  )
}
