import type { ReactElement } from 'react'
import { browserHistory } from 'react-router'
import { uniqueId } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'

import { deleteCustomer, logoutCustomer, setThemeNotification } from '../../../../store/actions'
import { generateShopUrl, withQueryAndScope } from '../../../../urlGenerators'
import Lightbox from '../../../templateComponents/Lightbox'
import Portal from '../../../templateComponents/Portal'
import translate from '../../../../utils/translate'

export default translate()(AccountDeleteModal)

type Props = {
  show: boolean
  onClose: () => void
} & TranslateProps

function AccountDeleteModal({ t, show, onClose }: Readonly<Props>): ReactElement {
  const scopedT: TranslateProps['t'] = (key, options) =>
    t(`views.storefrontView.customerAccountSection.settingsTab.deleteProfileSection.confirmationModal.${key}`, options)

  const dispatch = useDispatch()
  const apiDispatch: ApiActionDispatch = useDispatch<GlobalDispatch>()

  const [submitting, setSubmitting] = useState(false)

  const location = useSelector<State, ImmutableMap>((state) => state.get('location'))

  async function onDelete() {
    setSubmitting(true)

    try {
      await apiDispatch(deleteCustomer())

      dispatch(
        setThemeNotification({
          id: uniqueId(`customer-deleted-success-`),
          message: <span>{t('notifications.customerProfileDeleted')}</span>,
          type: 'success',
        }),
      )

      await apiDispatch(logoutCustomer())

      browserHistory.push(withQueryAndScope(generateShopUrl(), location))
    } catch {
      // global API call error handler takes care
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Portal>
      <Lightbox show={show} onClose={onClose} lightboxClassName="lightbox-modal">
        {({ close }) => (
          <div className="lightbox-modal-form account-delete-form">
            <div className="inner-content">
              <h2 className="title">{scopedT('title')}</h2>
              <p className="description">{scopedT('description')}</p>
            </div>
            <div className="button-wrapper">
              <button
                type="submit"
                onClick={onDelete}
                className="button button-delete button-pending-state pending"
                disabled={submitting}
              >
                {scopedT('deleteButton.label')}
              </button>
              <button type="button" onClick={close} className="button button-outline">
                {scopedT('backButton.label')}
              </button>
            </div>
          </div>
        )}
      </Lightbox>
    </Portal>
  )
}
