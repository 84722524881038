import { useSelector } from 'react-redux'

import Link from '../../../templateComponents/Link'
import translate from '../../../../utils/translate'

export default translate('views.storefrontView.customerAccountSection.orderOverview.emptyState')(OrderEmptyNote)

function OrderEmptyNote({ t }: TranslateProps) {
  const shopHref = useSelector<State, string>((state) => state.getIn(['shop', 'href']))

  return (
    <section className="subpage orders-empty">
      <p className="orders-empty-text">{t('explanation')}</p>
      <Link className="button button-primary" to={shopHref}>
        {t('continueShoppingLink')}
      </Link>
    </section>
  )
}
