import type { ReactElement } from 'react'
import { useState } from 'react'

import personalDataFieldsByCountry from '../../../templateComponents/Checkout/personalDataByCountry'

type Props = {
  tFormField: TranslateProps['t']
  countries?: ImmutableList
  address: Core.Address
} & TranslateProps

const nameFields = ['firstName', 'lastName']

export default function CustomerAddressFields({
  countries: allCountries,
  address,
  tFormField,
  t,
}: Readonly<Props>): ReactElement {
  const [country, setCountry] = useState(address.country || '')
  const countries = allCountries?.toJS() || []
  if (!country) countries.unshift({ value: '', label: t('countryInputField.placeholder') })

  const byFieldsToShow = country
    ? (field) => !field.hide // all fields for that country
    : ({ name }) => nameFields.includes(name) // only first and last name

  const fields = personalDataFieldsByCountry(country).filter(byFieldsToShow)

  return (
    <>
      {fields.map((field, index) => (
        <label key={field.name}>
          {tFormField(field.name)}
          {field.required && '*'}
          <input
            defaultValue={address[field.name] || ''}
            name={field.name}
            className="ep-form-text-field"
            required={field.required}
            type="text"
            // try to focus first field after name fields after selecting country
            autoFocus={index === 2}
          />
        </label>
      ))}

      <label>
        {tFormField('country')}*
        <select
          className="ep-form-text-field"
          name={`country`}
          value={country}
          required
          onChange={(e) => {
            setCountry(e.target.value)
          }}
        >
          {countries.map(({ value, label }) => (
            <option value={value} key={label}>
              {label}
            </option>
          ))}
        </select>
      </label>
    </>
  )
}
