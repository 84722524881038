import type { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { useState } from 'react'

import { addressTypeToString, areAddressesTheSame } from '../../../templateComponents/Checkout/utils'
import { getPlain } from '../../../../store/utils'
import AddressDataForm from './AddressDataForm'
import Lightbox from '../../../templateComponents/Lightbox'
import Portal from '../../../templateComponents/Portal'
import translate from '../../../../utils/translate'

export default translate('views.storefrontView.customerAccountSection.addressTab')(CustomerAddress)

function CustomerAddress({ t }: TranslateProps): ReactElement {
  const tShippingAddressEditModal = (fieldName: string) => t(`shippingAddressSection.editModal.${fieldName}`)
  const tBillingAddressEditModal = (fieldName: string) => t(`billingAddressSection.editModal.${fieldName}`)

  const billingAddress = getPlain(
    useSelector<State, Core.Customer['billingAddress']>((state) => state.getIn(['customer', 'billingAddress'], {})),
  )

  const shippingAddress = getPlain(
    useSelector<State, Core.Customer['shippingAddress']>((state) => state.getIn(['customer', 'shippingAddress'], {})),
  )

  const [showForm, setShowForm] = useState<null | 'billing-address-data' | 'shipping-address-data'>(null)
  const hideForm = () => setShowForm(null)

  function returnShippingAddressText() {
    if (shippingAddress.displayAddressLines?.length > 0) {
      if (areAddressesTheSame(billingAddress, shippingAddress)) {
        return t('shippingAddressSection.theSameAsBillingAddress')
      } else {
        return addressTypeToString(shippingAddress)
      }
    } else {
      return t('emptyState.explanation')
    }
  }

  return (
    <>
      <section className="subpage subpage-address">
        <div className="subpage-item">
          <div className="subpage-item-headline">
            <h2 className="subpage-item-headline-title">{t('billingAddressSection.title')}</h2>
            <button className="button-link" onClick={() => setShowForm('billing-address-data')}>
              <span className="button-link-text">{t('editButton.label')}</span>
            </button>
          </div>
          <p>
            {billingAddress.displayAddressLines?.length > 0
              ? addressTypeToString(billingAddress)
              : t('emptyState.explanation')}
          </p>
        </div>
        <div className="subpage-item">
          <div className="subpage-item-headline">
            <h2 className="subpage-item-headline-title">{t('shippingAddressSection.title')}</h2>
            <button className="button-link" onClick={() => setShowForm('shipping-address-data')}>
              <span className="button-link-text">{t('editButton.label')}</span>
            </button>
          </div>
          <p>{returnShippingAddressText()}</p>
        </div>
      </section>
      <Portal>
        <Lightbox show={Boolean(showForm)} onClose={hideForm} lightboxClassName="lightbox-modal">
          {({ close }) => (
            <>
              {showForm === 'billing-address-data' && (
                <AddressDataForm
                  t={tBillingAddressEditModal}
                  onClose={close}
                  billingAddress={billingAddress}
                  shippingAddress={shippingAddress}
                  scopeName="billingAddress"
                />
              )}
              {showForm === 'shipping-address-data' && (
                <AddressDataForm
                  t={tShippingAddressEditModal}
                  onClose={close}
                  billingAddress={billingAddress}
                  shippingAddress={shippingAddress}
                  scopeName="shippingAddress"
                />
              )}
            </>
          )}
        </Lightbox>
      </Portal>
    </>
  )
}
