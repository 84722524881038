import type { Location } from 'history'
import type { ReactElement } from 'react'
import type { RouteComponentProps, RouterState } from 'react-router'
import { browserHistory } from 'react-router'
import { camelCase } from 'lodash'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { confirmEmailChange, getCustomerDetails, logoutCustomer } from '../store/actions/customer'
import { generateCustomerAccountUrl, generateShopUrl, withQueryAndScope } from '../urlGenerators'
import { getPlain } from '../store/utils'
import Theme from '../components/Theme'
import usePrevious from '../utils/hooks/usePrevious'

type Props = RouteComponentProps<{ section?: string }, void> & TranslateProps

function Account({ params: { section } }: Props): ReactElement {
  const dispatch: ApiActionDispatch = useDispatch<GlobalDispatch>()
  const location = useSelector<State, ImmutableMap>((state) => state.get('location'))
  const plainLocation = getPlain<Location>(location)

  const redirectToLogin = useCallback(() => {
    const path = encodeURIComponent([generateCustomerAccountUrl(), section].filter(Boolean).join('/'))
    browserHistory.push(withQueryAndScope(`${generateShopUrl()}?login=1&redirect=${path}`, location))
  }, [location, section])

  useEffect(function handleConfirmEmailChange() {
    if (plainLocation.pathname.endsWith('confirm-email-change') && typeof plainLocation.query.tkn === 'string') {
      ;(async () => {
        if (typeof plainLocation.query.tkn !== 'string') return // shut up TypeScript

        try {
          await dispatch(confirmEmailChange(plainLocation.query.tkn))
          await dispatch(logoutCustomer())

          browserHistory.replace(withQueryAndScope(generateCustomerAccountUrl(), location))
        } catch {
          if (!isLoggedIn) redirectToLogin()
        }
      })()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const isLoggedIn = useSelector<State, boolean>((state) => state.getIn(['customer', 'loggedIn']))
  const previousLocationKey = usePrevious(location.get('key'))
  useEffect(
    function redirectToLoginWhenNeeded() {
      if (
        !isLoggedIn &&
        location.get('key') !== previousLocationKey &&
        location.get('pathname').startsWith('/customer-account')
      ) {
        redirectToLogin()
      }
    },
    [isLoggedIn, section, location, previousLocationKey, redirectToLogin],
  )

  const viewError = useSelector<State, ImmutableMap>((state) => state.getIn(['view', 'error']))
  useEffect(() => {
    if (viewError) dispatch(logoutCustomer())
  }, [dispatch, viewError])

  return viewError ? (
    <Theme withLayout error={viewError} currentView="Account" />
  ) : (
    <Theme withLayout currentView="Account">
      {(renderView, props) => renderView('Account', { ...props, section })}
    </Theme>
  )
}

Account.storeUpdate = (props: Props, state: State) => {
  const isForwardNavigation = props.location.action === 'PUSH'
  const updates: [ReturnType<typeof getCustomerDetails>?] = []

  if ((isForwardNavigation || !state.getIn(['customer', 'billingAddress'])) && state.getIn(['customer', 'loggedIn'])) {
    updates.push(getCustomerDetails())
  }

  return updates
}

Account.meta = (props: RouterState, state: State, t: TranslateProps['t']): View.Meta[] => {
  const section = props.params.section
  let title = t('views.storefrontView.customerAccountSection.profileTab.title')
  if (section && section !== 'confirm-email-change')
    title = t(`views.storefrontView.customerAccountSection.${camelCase(section)}Tab.title`)

  return [
    {
      title: `${title} - ${state.getIn(['shop', 'title'])}`,
    },
    {
      name: 'robots',
      content: 'noindex, follow',
    },
  ]
}

Account.contentCreationDisabled = () => true

export default Account
